import { useEffect, useState } from 'react'

import { Loading } from 'components'

import { useUserCoachingSessionsQuery } from 'gql'

import { useQueryParams } from 'hooks/useQueryParams'

import WeeklyLearningTimePage from './components/WeeklyLearningTimePage'
import { AddOrChooseLearningGoal } from './components/buyer/AddOrChoose'
import { BuyerPage } from './components/buyer/BuyerPage'
import TeamLearningTime from './components/buyer/TeamLearningTime'
import { Prompt } from './components/prompt/Prompt'
import TellUsMore from './components/prompt/TellUsMore'
import { Recommendations } from './components/recommendations/Recommendations'
import { LearningGoalSessionData } from './type'

const GoalsPage = () => {
  const { queryParams } = useQueryParams()
  const hasSessionId = queryParams.has('sessionId')
  const [activePage, setActivePage] = useState('prompt')
  const [activeSession, setActiveSession] = useState<LearningGoalSessionData | null>(null)
  const [allSessions, setAllSessions] = useState<LearningGoalSessionData[]>([])
  const [intialized, setInitialized] = useState(false)
  const { data, loading, refetch } = useUserCoachingSessionsQuery({
    variables: {
      kind: 'goals'
    }
  })

  useEffect(() => {
    if (data?.userCoachingSessions) {
      const sessiongId = queryParams.get('sessionId')
      const sessions: LearningGoalSessionData[] = data.userCoachingSessions.map(
        (session) => {
          return {
            id: session.id,
            externalId: session.externalId,
            status: session.status,
            completionPercentage: session.completionPercentage,
            data: { ...(session.data ?? {}) }
          }
        }
      )

      const maybeActiveSession = sessions.find(
        (session) => String(session.externalId) === String(sessiongId)
      )

      if (maybeActiveSession) {
        setActiveSession(maybeActiveSession)
      }

      console.log('the sessions', sessions)

      setAllSessions(sessions)
      setInitialized(true)
    }

    const currentStep = queryParams.get('step')
    if (currentStep === 'tell-us-more') {
      setActivePage('tell-us-more')
    } else if (currentStep === 'add-or-choose') {
      setActivePage('add-or-choose')
    } else if (
      currentStep === 'assign-goals' ||
      currentStep === 'select-team-for-goals'
    ) {
      setActivePage('assign-goals')
    } else if (currentStep === 'team-learning-time') {
      setActivePage('team-learning-time')
    } else if (queryParams.get('step') === 'weekly-learning-time') {
      setActivePage('weekly-learning-time')
    } else if (queryParams.has('sessionId')) {
      setActivePage('recommendations')
    } else {
      setActiveSession(null)
      setActivePage('prompt')
    }
  }, [data, queryParams])

  return (
    <div className="rounded-xl w-full overflow-auto">
      {loading && !intialized && <Loading />}
      {!loading && intialized && (
        <>
          {activePage === 'prompt' && !hasSessionId && <Prompt args={{ allSessions }} />}

          {activePage === 'tell-us-more' && <TellUsMore />}

          {activePage === 'weekly-learning-time' && <WeeklyLearningTimePage />}

          {activePage === 'team-learning-time' && (
            <TeamLearningTime activeSession={activeSession} />
          )}

          {(activePage === 'assign-goals' || activePage === 'select-team-for-goals') && (
            <BuyerPage />
          )}

          {activePage === 'add-or-choose' && <AddOrChooseLearningGoal />}

          {activePage === 'recommendations' && (
            <Recommendations
              args={{
                setActiveSession,
                activeSession,
                allSessions,
                refetchSessions: refetch
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

export default GoalsPage
