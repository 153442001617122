import dayjs from 'dayjs'
import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import CourseDetailsPageTopSection from 'pages/CourseDetailsPage/CourseDetailsPageTopSection'

import CourseFeatureGridModal from 'domains/Course/CourseFeatureGridModal'
import CourseDetailContent from 'domains/CourseDetail/CourseDetailContent'
import CourseDetailCreator from 'domains/CourseDetail/CourseDetailCreator'
import CourseDetailRelatedCourses from 'domains/CourseDetail/CourseDetailRelatedCourses'
import CourseDetailTestimonials from 'domains/CourseDetail/CourseDetailTestimonials'
import CourseDetailUpcomingSessions from 'domains/CourseDetail/CourseDetailUpcomingSessions'
import CourseDetailWhyTakeCourse from 'domains/CourseDetail/CourseDetailWhyTakeCourse'
import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import { Loading } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import { useModal } from 'components/Modal'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

import { MIN_WIDTH_TAILWIND_XL } from 'constants/breakpoints'
import { COURSES_ON_DEMAND_PATH, COURSES_PATH } from 'constants/courses'

import {
  useCourseDetailPageQuery,
  useTrackCourseEnrollmentStartedMutation,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useFirstSigninSource from 'hooks/useFirstSigninSource'
import useMediaQuery from 'hooks/useMediaQuery'

import { slugOfFirstGuideInCourseBlocks } from 'utils/courseUtils'
import { getAnonymousId } from 'utils/tracking/segment'

import EnrollmentSection from './EnrollmentSection'

interface CourseDetailsPageProps {
  loggedOut?: boolean
  courseSlug: string
}

const CourseDetailsPage = ({ courseSlug, loggedOut = false }: CourseDetailsPageProps) => {
  const { isLoggedIn } = useCurrentUser()
  const isLargerThanLg = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_XL})`)

  const { data, loading } = useCourseDetailPageQuery({
    variables: {
      slug: courseSlug
    }
  })

  const course = data?.cclCourse
  const relatedCourses = data?.relatedCourses
  const upcomingCourseSessions = data?.upcomingCourseSessions
  const courseDetails = course
  const { isModalOpen, setIsModalOpen } = useModal()
  const { currentUser } = useCurrentUser()
  const { setCustomSigninSource } = useFirstSigninSource()
  setCustomSigninSource('course_details__free_start_cta')

  const [trackCourseEnrollmentStarted] = useTrackCourseEnrollmentStartedMutation()
  const [trackServerEvent] = useTrackServerEventMutation()

  const { showNewCourseGating, showCourseLandingPageUpdates } = useFeatureFlags()

  useEffect(() => {
    if (!courseDetails) {
      return
    }

    const isConversionEligible = !(
      currentUser?.is?.paidMember && upcomingCourseSessions?.length === 0
    )

    trackServerEvent({
      variables: {
        input: {
          event: 'Conversion Page Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            page_type: 'course_details',
            path: window.location.pathname,
            referrer: document.referrer,
            url: window.location.href,
            is_conversion_eligible: isConversionEligible,
            related_identifiers: {
              ccl_course_id: courseDetails.id,
              course_title: courseDetails.title,
              course_sanity_id: courseDetails.sourceId,
              video_preview_include: courseDetails.trailerUrl !== undefined,
              number_live_sessions_available: upcomingCourseSessions?.length,
              is_available_on_demand: courseDetails.onDemand
            },
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
  }, [
    courseDetails,
    currentUser?.accessPolicyKind,
    currentUser?.is?.paidMember,
    trackServerEvent,
    upcomingCourseSessions?.length,
    currentUser?.trialStatus
  ])

  if (loading) return <Loading />

  if (!courseDetails) {
    return <Redirect to="/courses" />
  }

  const enrollableSessions = upcomingCourseSessions?.filter(
    (session) => session?.isDuringEnrollmentPeriod
  )

  const enrollableSession =
    enrollableSessions && enrollableSessions.length > 0 ? enrollableSessions[0] : null

  const hasMultipleEnrollableSessions = enrollableSessions
    ? enrollableSessions?.length > 1
    : false

  const shouldSeeOnDemandGating =
    showNewCourseGating &&
    (!isLoggedIn || (currentUser?.is?.freeUser && !currentUser.is?.expired)) &&
    !enrollableSession

  const onEnrollClick = () => {
    // Th CTA on this component only supports a single session
    if (!courseDetails?.slug || !enrollableSession) return

    trackCourseEnrollmentStarted({
      variables: {
        input: {
          ctaLocation: 'course_details_page',
          courseSlug: courseDetails?.slug,
          cclCourseSessionId: enrollableSession.id
        }
      }
    })

    const formattedStartDate = dayjs(enrollableSession?.startsAt).format('YYYY-MM-DD')

    window.location.href = `/course-payment/${courseDetails?.slug}/${formattedStartDate}/${enrollableSession?.id}`
  }

  const courseBlocks = [...(courseDetails?.blocks || [])].sort(
    (a, b) => a.displayOrder - b.displayOrder
  )

  const startCourseCTADestination = isLoggedIn
    ? `/courses/${course.slug}/on-demand/guides/${slugOfFirstGuideInCourseBlocks(courseBlocks)}?convertible=true`
    : `/login?reforgeLogin=true&referer=${encodeURIComponent(
        `/start/courses/${course.slug}/on-demand/guides/${slugOfFirstGuideInCourseBlocks(courseBlocks)}?convertible=true`
      )}`

  const renderCourseBlocks = () => {
    return courseBlocks.map((block, index) => {
      return (
        <CourseDetailContent
          key={block?.id}
          courseDetail={courseDetails}
          courseBlock={block}
          position={index + 1}
        />
      )
    })
  }

  return (
    <div className="flex flex-col">
      <div className="flex">
        {isLoggedIn && (
          <BaseBreadcrumbs
            oneLevel
            breadcrumbPages={[
              {
                title: 'all courses',
                path: showCourseLandingPageUpdates ? COURSES_PATH : COURSES_ON_DEMAND_PATH
              }
            ]}
            backIcon={
              <ChevronLeftIcon width={16} height={16} className="mr-4 fill-rb-black" />
            }
          />
        )}
      </div>
      <div
        className={twMerge(
          'relative flex flex-col justify-between gap-8 tl:flex-row xl:pt-8',
          loggedOut && 'justify-center px-4'
        )}
      >
        <div className="flex w-full max-w-[792px] flex-col gap-6 pb-32 sm:px-4">
          {/* Top section */}
          <div className="flex flex-col gap-6">
            <CourseDetailsPageTopSection
              courseDetails={courseDetails}
              upcomingCourseSessions={upcomingCourseSessions}
            />
            <EnrollmentSection
              className="xl:hidden"
              loggedOut={loggedOut}
              shouldSeeOnDemandGating={shouldSeeOnDemandGating}
              isLargerThanLg={isLargerThanLg}
              courseDetails={courseDetails}
              startCourseCTADestination={startCourseCTADestination}
              upcomingCourseSessions={upcomingCourseSessions}
              hasMultipleEnrollableSessions={hasMultipleEnrollableSessions}
              setIsModalOpen={setIsModalOpen}
              handleCtaClick={onEnrollClick}
            />
          </div>

          <div className="mt-5 flex max-w-[792px] flex-col gap-8">
            {/* Who this course is for Section */}
            {courseDetails?.audience && (
              <div>
                <RfHeader3 className="font-polysans !font-light">
                  <span className="text-[24px] font-light">Who this course is for</span>
                </RfHeader3>
                <UnitComplexPortableText
                  content={JSON.parse(courseDetails?.audience || '{}')}
                />
              </div>
            )}

            {/* Course outcomes */}
            {courseDetails?.goals && (
              <div>
                <RfHeader3 className="font-polysans !font-light">
                  <span className="text-[24px] font-light">Course outcomes</span>
                </RfHeader3>
                <UnitComplexPortableText
                  content={JSON.parse(courseDetails?.goals || '{}')}
                />
              </div>
            )}

            {/* Upcoming live courses */}
            {!!upcomingCourseSessions && upcomingCourseSessions.length > 0 && (
              <CourseDetailUpcomingSessions
                courseDetails={courseDetails}
                upcomingCourseSessions={upcomingCourseSessions}
                showTitle={hasMultipleEnrollableSessions}
              />
            )}

            {/* Course content */}
            {courseDetails?.outlineComplete &&
              courseBlocks &&
              courseBlocks.length > 0 && (
                <div>
                  <RfHeader3 className="font-polysans !font-light">
                    <span className="text-[24px] font-light">Course content</span>
                  </RfHeader3>
                  <div className="flex flex-col gap-4">{renderCourseBlocks()}</div>
                </div>
              )}

            {loggedOut && <CourseDetailWhyTakeCourse />}

            {/* What others are saying */}
            {courseDetails?.testimonials && courseDetails.testimonials.length > 0 && (
              <CourseDetailTestimonials
                courseDetail={courseDetails}
                testimonials={courseDetails.testimonials}
              />
            )}

            {/* Meet the creators */}
            {courseDetails?.creators && courseDetails.creators.length > 0 && (
              <div>
                <RfHeader3 className="font-polysans !font-light">
                  <span className="text-[24px] !font-light">
                    Meet the creator
                    {courseDetails.creators.length > 1 ? 's' : ''}
                  </span>
                </RfHeader3>

                <div className="space-y-4">
                  {courseDetails.creators.map((creator) => (
                    <CourseDetailCreator key={creator?.slug} creator={creator} />
                  ))}
                </div>
              </div>
            )}

            {/* Related courses */}
            {relatedCourses && relatedCourses.length > 0 && (
              <CourseDetailRelatedCourses
                relatedCourses={relatedCourses}
                courseDetails={courseDetails}
                loggedOut={loggedOut}
              />
            )}
          </div>
        </div>

        <EnrollmentSection
          className="hidden xl:flex"
          loggedOut={loggedOut}
          shouldSeeOnDemandGating={shouldSeeOnDemandGating}
          isLargerThanLg={isLargerThanLg}
          courseDetails={courseDetails}
          startCourseCTADestination={startCourseCTADestination}
          upcomingCourseSessions={upcomingCourseSessions}
          hasMultipleEnrollableSessions={hasMultipleEnrollableSessions}
          setIsModalOpen={setIsModalOpen}
          handleCtaClick={onEnrollClick}
        />

        <CourseFeatureGridModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </div>
  )
}

export default CourseDetailsPage
