import { isEqual, sortBy, uniqBy } from 'lodash'
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD } from 'pages/CoursesPage/CourseCard'
import {
  COURSE_FILTER_ALL_COURSES,
  COURSE_FILTER_LIVE,
  COURSE_FILTER_ON_DEMAND
} from 'pages/CoursesPage/CoursesFilters'
import CustomPageTitle from 'pages/CustomPageTitle'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { ErrorMessage } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import Button from 'components/Button'
import Loading from 'components/Loading'
import { usePage } from 'components/PageHeader/usePage'
import Tabs from 'components/Tabs'
import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
} from 'components/cards/Content/CourseCard'
import DropdownMultiSelect from 'components/dropdowns/DropdownMultiSelect/DropdownMultiSelect'
import DropdownSelect from 'components/dropdowns/DropdownSelect/DropdownSelect'

import { COURSES_MY_COURSES_PATH, COURSES_PATH } from 'constants/courses'

import {
  CclCourseCourseCardPartsFragment,
  useCoursesListFiltersQuery,
  useCoursesListLazyQuery,
  useCoursesListUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import {
  trackCtaClicked,
  trackFilterApplied,
  trackNavigationClicked,
  trackSortSelected
} from 'utils/tracking/analytics'

import {
  COURSE_SORT_TYPE_DURATION,
  COURSE_SORT_TYPE_POPULARITY,
  COURSE_SORT_TYPE_START_DATE,
  SORT_TYPE_LABEL_MAP
} from './CoursesListSort'
import MyCoursesTab from './MyCoursesTab'

const COURSES_TAB_MY_COURSES = 'my-courses'

type CombinedFiltersType = {
  topics: string[]
}

type FilterType = 'topics'

const combinedFilterCount = (combinedFilters: CombinedFiltersType) =>
  Object.keys(combinedFilters).reduce(
    (count, filterType: FilterType) => count + combinedFilters[filterType].length,
    0
  )

interface CoursesProps {
  activeTab: string
  filters: CombinedFiltersType
  sort: string
  pageLocation: string
  filterTypeJustApplied?: FilterType | null
  setFilterTypeJustApplied: Dispatch<SetStateAction<null | FilterType>>
  clearFiltersForType: (filterType: FilterType) => void
}

const PER_PAGE = 10

const Courses = ({
  activeTab,
  filters,
  pageLocation,
  sort,
  filterTypeJustApplied,
  setFilterTypeJustApplied
}: CoursesProps) => {
  const { isLoggedIn, currentUser } = useCurrentUser()

  const [currentTopics, setCurrentTopics] = useState(filters.topics)
  const [currentTab, setCurrentTab] = useState(activeTab)
  const [courses, setCourses] = useState<CclCourseCourseCardPartsFragment[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [totalResultsCount, setTotalResultsCount] = useState(0)

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const [fetchCourses, { error }] = useCoursesListLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (isEqual(filters.topics, currentTopics)) {
        return
      }
      if (currentTab !== activeTab) {
        // If the current tab is not the active tab, the filters will get reset (no-op)
        setCurrentTab(activeTab)
        return
      }
      setCurrentTopics(filters.topics)
      // tracking filterApplied here so can track the fetched result counts with this track
      if (filterTypeJustApplied && data) {
        trackFilterApplied({
          filter_location: pageLocation,
          filter_changed: filterTypeJustApplied,
          filters: filters,
          active_filter_count: combinedFilterCount(filters),
          results_count: data?.cclCourses?.totalCount || 0
        })

        setFilterTypeJustApplied(null)
      }
    }
  })

  const {
    data: userData,
    loading: bookmarksLoading,
    error: bookmarksError
  } = useCoursesListUserQuery({ skip: !isLoggedIn })

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const response = await fetchCourses({
        variables: {
          topicSlugs: filters.topics,
          availability: [COURSE_FILTER_LIVE, COURSE_FILTER_ON_DEMAND],
          sortOrder: sort,
          start: 0,
          limit: PER_PAGE
        }
      })
      setCourses(response.data?.cclCourses?.courses || [])
      setTotalResultsCount(response.data?.cclCourses?.totalCount || 0)
      setIsLoading(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort])

  if (bookmarksError || error) {
    return <ErrorMessage error={error} />
  }

  if (bookmarksLoading || isLoading || !courses) {
    return <Loading className="py-32" />
  }

  const courseBookmarks = userData?.currentUser?.courseBookmarks || []

  const additionalRelatedIdentifiers = {
    is_filtered_reference: combinedFilterCount(filters) > 0,
    is_empty_index_results: false
  }

  const onShowMoreClick = async (e: MouseEvent<HTMLButtonElement>) => {
    const text = (e.target as HTMLButtonElement).innerText.toLowerCase()

    setIsLoadingMore(true)
    const response = await fetchCourses({
      variables: {
        topicSlugs: filters.topics,
        availability: [COURSE_FILTER_LIVE, COURSE_FILTER_ON_DEMAND],
        sortOrder: sort,
        start: courses.length,
        limit: PER_PAGE
      },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache'
    })

    setCourses([...courses, ...(response.data?.cclCourses?.courses || [])])
    setIsLoadingMore(false)

    trackCtaClicked({
      text,
      destination: pageLocation,
      cta_type: 'button',
      cta_location: pageLocation,
      logged_in: isLoggedIn
    })
  }

  const destinationType = currentUser?.is.paidMember
    ? COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
    : COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS

  return (
    <>
      <div className="text-rb-gray-300 text-sm mb-8">{totalResultsCount} results</div>
      <div className="flex flex-col gap-4 mx-auto">
        {courses.map((course, idx) => {
          const bookmark = courseBookmarks.find(
            (courseBookmark) => courseBookmark.cclCourse?.id === course.id
          )
          const nextSession = course.upcomingSessions?.[0] || null

          return (
            <CourseCard
              destinationType={destinationType}
              pageLocation={pageLocation}
              locationType="index"
              activeTab={activeTab}
              additionalRelatedIdentifiers={additionalRelatedIdentifiers}
              variant={CardVariants.Horizontal}
              courseSession={nextSession}
              course={course}
              key={course.id}
              hideBookmarkButton={!isLoggedIn}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              bookmark={bookmark}
              showPremiumIconOverwrite={false}
              styleVariant="default"
              impressionTrackingProperties={{
                cclEntityId: course.id,
                location: `/courses ${activeTab}`,
                sectionImpressionIndex: idx
              }}
            />
          )
        })}
      </div>

      {courses.length < totalResultsCount && (
        <Button
          color="teal"
          onClick={onShowMoreClick}
          size="small"
          className="mx-auto mt-8"
          isLoadingSpinner={isLoadingMore}
        >
          View more courses
        </Button>
      )}

      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={userData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={!userData?.currentUser?.hasSeenCollectionsPrompt}
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction="bookmark"
      />
    </>
  )
}

function getValidSelectedTopics(
  allCourseTopics: Array<{ slug: string }>,
  selectedTopics: Array<string>
) {
  const validFilterSlugs = allCourseTopics.map((topic) => topic.slug)
  return selectedTopics.filter((slug) => validFilterSlugs.includes(slug))
}

function getAllTopicsFromCoursesListFiltersQueryResult(
  data: ReturnType<typeof useCoursesListFiltersQuery>['data']
) {
  const cclCourseCclTopics = data?.cclCourseCclTopics || []

  const allCourseTopics = sortBy(uniqBy([...cclCourseCclTopics], 'id'), 'title')

  return allCourseTopics
}

export const CoursesList = () => {
  const history = useHistory()
  const { isLoggedIn, currentUser } = useCurrentUser()
  const { setPageTitle, setPageAboveHeader, setPageSubtitle } = usePage()
  const location = useLocation()

  const { ref16263TopicBasedBrowsing } = useFeatureFlags()

  const locationSearchString = location.search
  const searchParams = useMemo(
    () => new URLSearchParams(locationSearchString),
    [locationSearchString]
  )
  const [tabSearchParam, setTabSearchParam] = useState(() => {
    const tabParam = searchParams.get('tab')
    if (tabParam === COURSE_FILTER_LIVE) {
      return COURSE_FILTER_ALL_COURSES
    }
    return tabParam || COURSE_FILTER_ALL_COURSES
  })

  const selectedFilters = useMemo(() => {
    return {
      topics: searchParams.get('topics')?.split(',').filter(Boolean) || []
    }
  }, [searchParams])

  const [selectedSortType, setSelectedSortType] = useState(COURSE_SORT_TYPE_POPULARITY)

  const [filterTypeJustApplied, setFilterTypeJustApplied] = useState<null | FilterType>(
    null
  )

  useEffect(() => {
    if (!isLoggedIn || currentUser?.is.freeUser) {
      setPageTitle(<CustomPageTitle title="Courses" />)
    }

    if (isLoggedIn) {
      if (ref16263TopicBasedBrowsing) {
        setPageAboveHeader(
          <div className="mb-8">
            <BaseBreadcrumbs
              backIcon={
                <ChevronLeftIcon width={16} height={16} className="mr-4 fill-rb-black" />
              }
              oneLevel
              onClick={() => {
                trackNavigationClicked({
                  type: 'hyperlink text',
                  text: 'back to explore',
                  location: location.pathname,
                  destination: '/explore'
                })
              }}
              breadcrumbPages={[{ title: 'Explore', path: '/explore' }]}
            />
          </div>
        )
      }

      setPageSubtitle('Gain the expertise to unlock step-change career growth')
    }

    return () => {
      setPageTitle(null)
      setPageAboveHeader(null)
      setPageSubtitle(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoggedIn, location.pathname, ref16263TopicBasedBrowsing])

  const setTopicsQueryParam = useCallback(
    (topics: string[]) => {
      const topicSlugs = topics.join(',')
      if (topicSlugs.length === 0) {
        searchParams.delete('topics')
      } else {
        searchParams.set('topics', topicSlugs)
      }

      history.replace({
        pathname: window.location.pathname,
        search: searchParams.toString()
      })

      setFilterTypeJustApplied('topics')
    },
    [searchParams, history]
  )

  const { data, loading, error } = useCoursesListFiltersQuery({
    onCompleted: (data) => {
      const allTopicsFilters = getAllTopicsFromCoursesListFiltersQueryResult(data)
      const validSelectedTopics = getValidSelectedTopics(
        allTopicsFilters,
        selectedFilters.topics
      )

      setTopicsQueryParam(validSelectedTopics)
    }
  })

  const allCourseTopics = getAllTopicsFromCoursesListFiltersQueryResult(data)

  const clearFiltersForType = useCallback(
    (filterType: FilterType) => {
      searchParams.delete(filterType)

      history.replace({
        pathname: window.location.pathname,
        search: searchParams.toString()
      })
    },
    [searchParams, history]
  )

  const pageLocation = useMemo(() => {
    if (isLoggedIn) {
      if (currentUser?.is?.member) {
        return '/courses (paid)'
      }
      return '/courses (free)'
    }
    return '/courses (anon)'
  }, [isLoggedIn, currentUser])

  const handleTopicSelection = useCallback(
    (topics: string[]) => {
      setTopicsQueryParam(topics)
    },
    [setTopicsQueryParam]
  )

  const handleSelectSortType = useCallback(
    (sortType: string) => {
      if (sortType === selectedSortType) return

      trackSortSelected({
        location: pageLocation,
        sort_by: sortType.toLowerCase()
      })
      setSelectedSortType(sortType)
    },
    [selectedSortType, pageLocation]
  )

  const courseFilters = useMemo(() => {
    return {
      topics: selectedFilters.topics
    }
  }, [selectedFilters])

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const tabs = isLoggedIn
    ? [
        {
          label: 'All courses',
          onClick: () => {
            if (tabSearchParam === 'my-courses') {
              setTabSearchParam(COURSE_FILTER_ALL_COURSES)
              history.push(COURSES_PATH)
            }

            trackNavigationClicked({
              text: 'all courses',
              destination: COURSES_PATH,
              type: 'pill',
              location: '/courses'
            })
          },
          isActive: tabSearchParam === COURSE_FILTER_ALL_COURSES
        },
        {
          label: 'My courses',
          onClick: () => {
            if (tabSearchParam === 'all') {
              setTabSearchParam('my-courses')
              history.push(COURSES_MY_COURSES_PATH)
            }

            trackNavigationClicked({
              text: 'my courses',
              destination: COURSES_MY_COURSES_PATH,
              type: 'pill',
              location: '/courses'
            })
          },
          isActive: tabSearchParam === COURSES_TAB_MY_COURSES
        }
      ]
    : []

  return (
    <div className="flex w-full flex-col">
      {isLoggedIn ? (
        <div className="flex items-center space-x-4">
          <Tabs tabs={tabs} />
        </div>
      ) : (
        <>
          <h3 className="text-rb-gray-400 text-[28px] leading-[1.2] font-medium mb-2 font-sans">
            All Reforge courses
          </h3>
          <p className="text-rb-gray-400 text-xl leading-[1.4] font-medium mb-0">
            Learn from experts on-demand, at your own pace, and on your schedule
          </p>
        </>
      )}
      <div className="flex justify-between my-8">
        {allCourseTopics.length > 0 && tabSearchParam !== COURSES_TAB_MY_COURSES && (
          <DropdownMultiSelect
            displayText="Topics"
            data={allCourseTopics.map((topic) => ({
              label: topic.title,
              value: topic.slug
            }))}
            className="h-[40px] w-[110px] md:min-w-[220px]"
            dropdownClassName="min-w-[220px] w-full"
            containerClassName={'w-[110px] md:w-full'}
            selectedItems={selectedFilters.topics}
            onSelection={handleTopicSelection}
          />
        )}
        {tabSearchParam !== COURSES_TAB_MY_COURSES && (
          <DropdownSelect
            data={[
              {
                value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_POPULARITY], // the value and label are backwards because the dropdown renders the value as the option text
                label: COURSE_SORT_TYPE_POPULARITY
              },
              {
                value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_START_DATE],
                label: COURSE_SORT_TYPE_START_DATE
              },
              {
                value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_DURATION],
                label: COURSE_SORT_TYPE_DURATION
              }
            ]}
            value={selectedSortType}
            onChange={handleSelectSortType}
            label={'Sort By'}
            className={
              'hidden sm:flex w-[350px] shrink-0 md:w-[300px] flex-row items-center justify-end gap-2'
            }
            labelClassName="text-rb-gray-300"
            dropdownClassName="-right-3"
            noBorder
          />
        )}
      </div>
      {tabSearchParam === COURSES_TAB_MY_COURSES ? (
        <MyCoursesTab />
      ) : (
        <Courses
          activeTab={tabSearchParam}
          filters={courseFilters}
          sort={selectedSortType}
          pageLocation={pageLocation}
          setFilterTypeJustApplied={setFilterTypeJustApplied}
          filterTypeJustApplied={filterTypeJustApplied}
          clearFiltersForType={clearFiltersForType}
        />
      )}
    </div>
  )
}

export default CoursesList
