import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import LiveCourseEnrollmentSection from 'pages/CourseDetailsPage/LiveCourseEnrollmentSection'

import { CclCourseSessionPartsFragment, CourseDetailPartsFragment } from 'gql'

import OnDemandGetStartedCard from './OnDemandGetStartedCard'

interface EnrollmentSectionProps {
  loggedOut: boolean
  shouldSeeOnDemandGating: boolean
  isLargerThanLg: boolean
  className: string
  courseDetails?: CourseDetailPartsFragment
  upcomingCourseSessions?: CclCourseSessionPartsFragment[] | null
  startCourseCTADestination: string
  hasMultipleEnrollableSessions: boolean
  setIsModalOpen: (isOpen: boolean) => void
  handleCtaClick: () => void
}

const EnrollmentSection: FC<EnrollmentSectionProps> = ({
  loggedOut,
  shouldSeeOnDemandGating,
  className,
  courseDetails,
  startCourseCTADestination,
  upcomingCourseSessions,
  setIsModalOpen,
  handleCtaClick
}) => {
  const canEnrollInLiveCourse = upcomingCourseSessions?.[0]?.seatsLeft || false

  const showLiveCourseEnrollmentSection =
    !shouldSeeOnDemandGating && canEnrollInLiveCourse

  const shouldShowOnDemandOnlyCard =
    shouldSeeOnDemandGating && !canEnrollInLiveCourse && courseDetails?.onDemand

  return (
    <div
      className={twMerge(
        'flex h-full xl:max-h-screen w-full flex-col gap-4 xl:max-w-[360px] sticky',
        loggedOut ? 'top-28' : 'top-16',
        className
      )}
    >
      {showLiveCourseEnrollmentSection && (
        <LiveCourseEnrollmentSection
          loggedOut={loggedOut}
          courseDetails={courseDetails}
          upcomingCourseSessions={upcomingCourseSessions}
          startCourseCTADestination={startCourseCTADestination}
          handleCtaClick={handleCtaClick}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {shouldShowOnDemandOnlyCard && (
        <OnDemandGetStartedCard
          startCourseCTADestination={startCourseCTADestination}
          handleCtaClick={handleCtaClick}
        />
      )}
    </div>
  )
}

export default EnrollmentSection
