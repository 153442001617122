import { addMinutes, addWeeks, format, set } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useEffect, useState } from 'react'

import AddToCalendar from 'components/AddToCalendar'
import Button from 'components/Button'
import CheckboxFilter from 'components/CheckboxFilter'
import DropdownSelect from 'components/dropdowns/DropdownSelect/DropdownSelect'

import { useGenerateAiLearningGoalsMutation } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import { useQueryParams } from 'hooks/useQueryParams'

import { displayErrorToast } from 'utils/toastService'

// import { ReactComponent as CalendarIcon } from 'images/icon--calendar-white.svg'
import { ReactComponent as LockIcon } from 'images/icon--lock.svg'
import { ReactComponent as PencilIcon } from 'images/icon--pencil-edit.svg'

import { LoadingAnimation } from './LoadingAnimation'
import { SimpleProgressBar } from './ProgressBar'

const DAYS_CHECKBOXES = [
  { name: 'Monday', boxLabel: 'Monday', checked: false },
  { name: 'Tuesday', boxLabel: 'Tuesday', checked: false },
  { name: 'Wednesday', boxLabel: 'Wednesday', checked: false },
  { name: 'Thursday', boxLabel: 'Thursday', checked: false },
  { name: 'Friday', boxLabel: 'Friday', checked: true },
  { name: 'Saturday', boxLabel: 'Saturday', checked: false },
  { name: 'Sunday', boxLabel: 'Sunday', checked: false }
]

const dayAbbreviations: { [key: string]: string } = {
  Monday: 'MO',
  Tuesday: 'TU',
  Wednesday: 'WE',
  Thursday: 'TH',
  Friday: 'FR',
  Saturday: 'SA',
  Sunday: 'SU'
}

const FILTER_NAME = 'kind'

const MINUTES_SELECTION = [
  { value: '30 minutes', label: '30' },
  { value: '60 minutes', label: '60' },
  { value: '90 minutes', label: '90' }
]

const WeeklyLearningTimePage = () => {
  const currentUser = useAssertCurrentUser()
  // Page state
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  // DropdownSelect state
  const [selectedTime, setSelectedTime] = useState('30')

  // CheckboxFilter state
  const [checkboxes, setCheckboxes] = useState(DAYS_CHECKBOXES)
  const [selectedDays, setSelectedDays] = useState(['Friday'])
  const [filters, setFilters] = useState({
    kind: ['Friday']
  })

  // Other state
  const [showATC, setShowATC] = useState(false)
  const { setNewParams } = useQueryParams()
  const [generateAiLearningGoals] = useGenerateAiLearningGoalsMutation()

  const pencilIcon = <PencilIcon className="mx-0.1 w-4 self-center text-rb-gray-250" />
  // const calendarIcon = (
  //   <CalendarIcon className="mx-0.1 w-4 self-center text-rb-gray-250" />
  // )

  useEffect(() => {
    const newSelectedDays = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.name)
    setSelectedDays(newSelectedDays)
  }, [checkboxes])

  useEffect(() => {
    if (showATC) {
      // Refreshing AddEvent api as it is synchronous
      // @ts-ignore
      window.addeventatc?.refresh()
    }
  }, [showATC])

  // Main page button handlers
  const handleContinueMain = async () => {
    setIsLoading(true)

    const { data } = await generateAiLearningGoals({
      variables: {
        input: {
          data: {
            prompt: localStorage.getItem('goal-prompt'),
            experience: localStorage.getItem('goal-experience'),
            why: localStorage.getItem('goal-why'),
            learning: {
              time: selectedTime,
              days: filters.kind
            }
          }
        }
      }
    })

    const results: any = data?.generateAiLearningGoals

    if (results?.success) {
      const externalId = results.learningGoalSession.externalId
      const keys = ['goal-prompt', 'goal-experience', 'goal-why']
      keys.forEach((k) => localStorage.removeItem(k))

      setNewParams({ step: 'recommendations', sessionId: externalId })
    } else {
      displayErrorToast({
        message:
          'Something went wrong while trying to generate the goals please try again.'
      })
    }
    setIsLoading(false)
  }

  const handleEdit = () => {
    setIsEdit(true)
  }

  const handleBackMain = () => {
    setNewParams({ step: 'tell-us-more' })
  }

  const handleCalendar = () => {
    setShowATC(!showATC)
  }

  // Edit page button handlers
  const handleSelectTime = (newTime: string) => {
    if (selectedTime === newTime) return

    setSelectedTime(newTime)
  }

  const handleSetFilters = (newFilters: { kind: string[] }) => {
    const transformedFilters = {
      kind: newFilters.kind || []
    }

    setFilters(transformedFilters)
  }

  const handleContinueEdit = () => {
    setIsEdit(false)
  }

  const handleBackEdit = () => {
    setIsEdit(false)
  }

  const handleOnClickCallback = () => {
    console.log('Add to calendar clicked')
    // Add tracking here
  }

  const getAddToCalendarProps = () => {
    const timezone = currentUser.timezone || 'America/New_York'

    const now = new Date()
    // Generating temporary future end-date
    const periodEnd = addWeeks(now, 3)

    const defaultDailyStart = set(now, {
      hours: 12,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    })
    const endBySelectedDuration = addMinutes(defaultDailyStart, parseInt(selectedTime))

    const zonedNowAt3PM = utcToZonedTime(defaultDailyStart, timezone)
    const zonedNowAt325PM = utcToZonedTime(endBySelectedDuration, timezone)
    const zonedPeriodEnd = utcToZonedTime(periodEnd, timezone)

    const until = format(zonedPeriodEnd, "yyyyMMdd'T'HHmmss")
    const byDayString = selectedDays
      .map((day) => dayAbbreviations[day as keyof typeof dayAbbreviations])
      .join(',')
    const recurring = `FREQ=WEEKLY;UNTIL=${until};BYDAY=${byDayString}`

    const startsAtUtc = format(zonedNowAt3PM, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    const endsAtUtc = format(zonedNowAt325PM, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

    return {
      timezone: 'America/Denver',
      startsAtUtc: startsAtUtc,
      endsAtUtc: endsAtUtc,
      eventTitle: 'Reforge Learning Time',
      eventDescription: 'This is a sample event.',
      joinLink: 'http://example.com',
      recurring: recurring,
      className: '!shadow-none',
      onClickCallback: () => {
        handleOnClickCallback()
      }
    }
  }

  if (isLoading) {
    return <LoadingAnimation requestCompleted={false} onCompleted={() => {}} />
  }

  if (isEdit) {
    return (
      <div className="flex flex-col items-left bg-white p-8 rounded-lg max-w-3xl mx-auto">
        <div className="text-3xl font-medium mb-2">Set up your weekly learning time</div>
        <div className="font-normal text-rb-gray-300 mb-4">
          People that block time are more successful in meeting their goals
        </div>
        <span>How much time can you dedicate to focus on learning each week?</span>
        <DropdownSelect
          data={MINUTES_SELECTION}
          value={selectedTime}
          onChange={handleSelectTime}
          className={
            'flex w-[350px] shrink-0 md:w-[300px] flex-row items-center gap-2 mt-2'
          }
        />
        <CheckboxFilter
          checkboxes={checkboxes}
          filters={filters}
          label="Choose the days of the week where you can fit in the learning time:"
          name={FILTER_NAME}
          setFilters={handleSetFilters}
          text="This will help us block time on your calendar. We recommend 1-2 days to start."
          setCheckboxes={setCheckboxes}
          neverCollapse={true}
          hideReset={true}
          hideBorder={true}
          collapseOnOutsideClick={false}
        />
        <div className="mt-10 mb-10 flex justify-between items-center">
          <SimpleProgressBar totalSteps={2} completed={2} />
          <div className="flex">
            <Button
              className="h-10 mr-1"
              variant="text-only"
              size="small"
              onClick={handleBackEdit}
            >
              Back
            </Button>
            <Button className="h-10" size="small" onClick={() => handleContinueEdit()}>
              Continue
            </Button>
          </div>
        </div>
        <div className="flex space-x-5 text-rb-gray-250">
          <LockIcon className="mr-3 mx-1 w-2.5 self-center text-rb-gray-250" />
          We keep your responses confidential and your information is never shared
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-left h-screen bg-white p-8 rounded-lg max-w-3xl mx-auto">
      <div className="text-3xl font-medium mb-2">Set up your weekly learning time</div>
      <div className="font-normal text-rb-gray-300 mb-4">
        People that block time are more successful in meeting their goals
      </div>
      <div className="min-h-[150px] rounded-xl bg-[#EFF9F9] w-full flex items-center p-4 overflow-auto">
        <div className="flex flex-col">
          <p className="mb-1 font-bold">Recommended</p>
          <ul className="list-disc list-inside ml-[-20px]">
            <li>{selectedTime} minutes per week</li>
            <li>{selectedDays.join(', ')}</li>
          </ul>
        </div>
        <div className="ml-auto flex justify-center">
          <Button
            className="h-10 mr-3"
            variant="outline"
            size="small"
            iconBefore={pencilIcon}
            onClick={() => handleEdit()}
          >
            Edit
          </Button>

          {showATC && (
            <div>
              <AddToCalendar {...getAddToCalendarProps()} />
              <Button
                className="h-10"
                color="teal"
                size="small"
                onClick={() => handleCalendar()}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </div>
      <Button
        className="py-2 px-5 h-10 mt-5 mb-10"
        variant="outline"
        size="small"
        onClick={handleContinueMain}
      >
        {"I'll do this later"}
      </Button>
      <div className="mb-10 flex justify-between items-center">
        <SimpleProgressBar totalSteps={2} completed={2} />
        <div className="flex">
          <Button
            className="h-10 mr-1"
            variant="text-only"
            size="small"
            onClick={handleBackMain}
          >
            Back
          </Button>
          <Button className="h-10" size="small" onClick={handleContinueMain}>
            Continue
          </Button>
        </div>
      </div>
      <div className="flex space-x-5 text-rb-gray-250">
        <LockIcon className="mr-3 mx-1 w-2.5 self-center text-rb-gray-250" />
        We keep your responses confidential and your information is never shared
      </div>
    </div>
  )
}

export default WeeklyLearningTimePage
