import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import Dropdown from 'components/forms/Dropdown'

import { useGenerateAiLearningGoalsMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useQueryParams } from 'hooks/useQueryParams'

import { cn } from 'utils/tailwind'
import { displayErrorToast } from 'utils/toastService'

import { ReactComponent as LockIcon } from 'images/icon--lock.svg'

import { LoadingAnimation } from '../LoadingAnimation'
import { SimpleProgressBar } from '../ProgressBar'

const TellUsMore = () => {
  const [experience, setExperience] = useState('')
  const [why, setWhy] = useState('')
  const history = useHistory()
  const { setNewParams } = useQueryParams()
  const { currentUser } = useCurrentUser()
  const [premadeGoalLoading, setPremadeGoalLoading] = useState(false)
  const [generateAiLearningGoals] = useGenerateAiLearningGoalsMutation()

  const handleContinue = async () => {
    if (currentUser?.is?.planManager || currentUser?.is?.teamSubscriptionOwner) {
      setPremadeGoalLoading(true)

      try {
        const { data } = await generateAiLearningGoals({
          variables: {
            input: {
              data: {
                prompt: localStorage.getItem('goal-prompt'),
                experience: localStorage.getItem('goal-experience'),
                why: localStorage.getItem('goal-why'),
                learning: {
                  time: 30,
                  days: ['friday']
                }
              }
            }
          }
        })

        const results: any = data?.generateAiLearningGoals

        if (results?.success) {
          const externalId = results.learningGoalSession.externalId
          const keys = ['goal-prompt', 'goal-experience', 'goal-why']
          keys.forEach((k) => localStorage.removeItem(k))
          setNewParams({ step: 'recommendations', sessionId: externalId })
        } else {
          displayErrorToast({
            message:
              'Something went wrong while trying to generate the goals please try again.'
          })
        }
      } catch (error) {
        displayErrorToast({
          message: 'An error occurred while processing your request.'
        })
      }
    } else {
      setNewParams({ step: 'weekly-learning-time' })
    }
  }

  const dropdownOptions = () => {
    if (currentUser?.is?.planManager || currentUser?.is?.teamSubscriptionOwner) {
      return [
        {
          label: 'This is a new area for our team',
          id: 'This is a new area for our team'
        },
        {
          label: 'Our team needs a quick refresher',
          id: 'Our team needs a quick refresher'
        },
        {
          label: 'We need to level up team skills in this area',
          id: 'We need to level up team skills in this area'
        },
        {
          label: 'We need to deepen our collective expertise',
          id: 'We need to deepen our collective expertise'
        }
      ]
    }

    return [
      {
        label: "I've never done this before",
        id: "I've never done this before"
      },
      {
        label: 'I need a quick refresher',
        id: 'I need a quick refresher'
      },
      {
        label: 'I need to level up existing skills in this area',
        id: 'I need to level up existing skills in this area'
      },
      {
        label: 'I need to deepen my expertise on this topic',
        id: 'I need to deepen my expertise on this topic'
      }
    ]
  }

  useEffect(() => {
    setExperience(localStorage.getItem('goal-experience') || '')
    setWhy(localStorage.getItem('goal-why') || '')
  }, [])

  function adjustHeight(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = 'inherit'
      e.target.style.height = `${e.target.scrollHeight}px`
    }
  }

  const textAreaRef = useRef(null)

  useEffect(() => {
    const textArea: any = textAreaRef.current
    if (textArea) {
      textArea.style.height = 'auto'
      textArea.style.height = `${textArea.scrollHeight}px`
    }
  }, [why])

  return (
    <div className="flex bg-white w-full justify-center">
      {premadeGoalLoading && (
        <LoadingAnimation requestCompleted={false} onCompleted={() => {}} />
      )}
      <div className={cn('max-w-2xl', premadeGoalLoading && 'hidden')}>
        <div className="text-[28px] font-medium mb-2">Tell us more about your goal</div>
        <div className="font-normal text-rb-gray-300 mb-6">
          This will customize your goal to drive increased task performance and motivation
        </div>
        <div className="flex flex-col rounded-xl w-full">
          <div className="font-sans text-rb-gray-400 font-semibold mb-2">
            Why are you focused on this goal now?
          </div>
          <div className="font-normal text-rb-gray-300 mb-4">
            This will help us surface more timely & personalized content.
          </div>

          <div className="px-6 my-6 py-3 border bg-white flex items-center border-rb-gray-100 justify-between w-full rounded-xl">
            <div className="mr-1 flex-1 flex items-center"></div>
            <textarea
              ref={textAreaRef}
              id="locationInput"
              className={cn(
                'border-0 min-h-6 rounded-lg block text-rb-gray-400 text-sm font-medium px-1 py-1 w-full resize-none',
                'focus:bg-transparent active:bg-transparent focus:outline focus:outline-none active:outline active:outline-none'
              )}
              name="why"
              value={why}
              placeholder="Add your why"
              onChange={(e) => {
                const val = e.target.value
                setWhy(val)
                localStorage.setItem('goal-why', val)
                adjustHeight(e)
              }}
              rows={1}
            />
          </div>
        </div>

        <div className="flex flex-col rounded-xl w-full my-8">
          <div className="font-sans text-rb-black font-semibold mb-2">
            {currentUser?.is?.planManager || currentUser?.is?.teamSubscriptionOwner
              ? "What is your team's level of experience?"
              : 'What is your level of experience?'}
          </div>
          <div className="font-normal text-rb-gray-300 mb-4">
            This will help us understand the depth needed in content & recommendations.
          </div>

          <Dropdown
            options={dropdownOptions()}
            buttonClassName="rounded-md"
            placeholder="Select experience level"
            selection={experience}
            setSelection={(value = '') => {
              setExperience(value as string)
              localStorage.setItem('goal-experience', value as string)
            }}
          />
        </div>

        <div className="mb-10 flex justify-between items-center">
          <SimpleProgressBar totalSteps={2} completed={1} />
          <div className="flex">
            <Button
              className="h-10 mr-1"
              variant="text-only"
              size="small"
              onClick={() => {
                history.replace('/goals')
              }}
            >
              Back
            </Button>
            <Button
              className="h-10"
              size="small"
              disabled={!why || !experience}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
        <div className="flex space-x-5 text-rb-gray-250">
          <LockIcon className="mr-3 mx-1 w-2.5 self-center text-rb-gray-250" />
          We keep your responses confidential and your information is never shared
        </div>
      </div>
    </div>
  )
}

export default TellUsMore
